.pro-sidebar {
  z-index: 0;
}

#header .pro-sidebar {
  height: calc(100vh - 80px);
  min-width: 250px;
  width: 250px;
}

@media only screen and (max-width: 1023px) {
  #header .pro-sidebar {
    width: 100%;
    min-width: auto;
  }
}

#header .pro-sidebar-header {
  border-bottom: 0;
}

#header .closemenu {
  color: #56557B;
  z-index: 9999;
  font-size: 23px;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  text-align: center;
  align-items: center;
  line-height: normal;
  justify-content: center;
}

#header .closemenu svg {
  margin-right: 10px;
}

#header .closemenu span {
  font-size: 20px;
  line-height: 1.1;
}

#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}

.pro-sidebar > .pro-sidebar-inner {
  background-color: transparent;
}

@media screen and (max-width: 1023px) {
  #header .pro-sidebar-inner {
    height: auto;
  }
}

#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}

@media screen and (max-width: 1023px) {
  #header .pro-sidebar-inner .pro-sidebar-layout {
    height: calc(100vh - 72px);
    overflow-y: auto;
  }
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 20px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 10px;
}

#header .pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  font-weight: bold;
  padding: 12px 16px;
  border-radius: 6px;
}

#header .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover {
  background: #FFEAFC;
}

#header .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item:hover .pro-item-content {
  color: #F313CE;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
  background-color: transparent;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-item-content {
  color: #fff;
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  padding-right: 25px;
  transition: color .25s;
  letter-spacing: 0.16px;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background: #FFEAFC;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active .sub-icon-normal {
  opacity: 0;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active .sub-icon-active {
  opacity: 1;
}


#header .pro-sidebar-inner .pro-sidebar-layout .active .pro-inner-item .pro-item-content {
  color: #F313CE;
}

#header .logo {
  padding: 20px;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item:before {
  display: none;
}

#header .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  width: 22px;
  min-width: 22px;
  height: 22px;
}

#header .pro-icon-wrapper .pro-icon {
  position: relative;
}

#header .pro-icon-wrapper .pro-icon img {
  position: absolute;
  transition: opacity .25s;
}

#header .pro-menu-item.pro-sub-menu .pro-inner-item {
  margin-bottom: 5px;
}

#header .pro-menu-item.pro-sub-menu.open .main-icon-active {
  opacity: 0 !important;
}

#header .pro-menu-item.pro-sub-menu.open:hover .main-icon-active,
#header .pro-menu-item.pro-sub-menu.open .pro-inner-list-item .pro-menu-item.sub-item-menu.active .sub-icon-active {
  opacity: 1 !important;
}

#header .pro-icon-wrapper .icon-active,
#header .pro-menu-item.pro-sub-menu .main-icon-active,
#header .pro-menu-item.sub-item-menu .sub-icon-active,
#header .pro-menu-item:hover .icon-normal,
#header .pro-menu-item.sub-item-menu:hover .sub-icon-normal {
  opacity: 0;
}

#header .pro-menu-item:hover .icon-active,
#header .pro-menu-item.pro-sub-menu:hover .main-icon-active,
#header .pro-menu-item.pro-sub-menu.open .main-icon-active,
#header .pro-menu-item.active .icon-active,
#header .pro-menu-item.sub-item-menu:hover .sub-icon-active {
  opacity: 1;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item>.pro-icon-wrapper {
  display: inline;
  width: 22px;
  min-width: 22px;
  height: 22px;
}

#header .pro-sidebar .pro-menu .pro-menu-item {
  transition: all .25s;
  border-radius: 6px;
  padding: 0;
}

#header .pro-sidebar .pro-menu .pro-menu-item:not(:last-child) {
  margin-bottom: 5px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}

#header .pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background: transparent;
}

#header .pro-sidebar .pro-menu .pro-menu-item>.pro-inner-item>.pro-item-content {
  display: flex;
  align-items: center;
}

#header .sub-item-menu .text {
  padding-left: 5px;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 15px;
}

#header .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu>.pro-inner-item>.pro-arrow-wrapper {
  right: 15px;
}

#header .pro-sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 10px;
  overflow-y: auto;
}